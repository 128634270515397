import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes = [
   // 官网
   {
    path: '/',
    name: 'index',
    component: () => import('../views/home.vue')
  },
  // 确认借款页
  {
    path: '/straightaway',
    name: 'loan_Information',
    component: () => import('../views/loan_Information.vue')
  },
  // 订单详情页（待还款、审核中、放款、结清、拒绝等）
  {
    path: '/leavetheir',
    name: 'cart_Info',
    component: () => import('../views/cart_Info.vue')
  },
  // 还款--选择期数
  {
    path: '/goodReports',
    name: 'repayment_Period',
    component: () => import('../views/repayment_Period.vue')
  },
  // 还款--展期
  {
    path: '/anneknew',
    name: 'repayment_Defer',
    component: () => import('../views/repayment_Defer.vue')
  },
  
  // 还款--还款方式 tabs切换
  {
    path: '/repayment_Method',
    name: 'repayment_Method',
    component: () => import('../views/repayment_Method.vue')
  },
  // 还款--还款码
  {
    path: '/repayment_Code',
    name: 'repayment_Code',
    component: () => import('../views/repayment_Code.vue')
  },
  // 联系我们
  {
    path: '/quarter',
    name: 'contact_Us',
    component: () => import('../views/contact_Us.vue')
  },
  // 复贷弹框推荐列表
  {
    path: '/frightfully',
    name: 'Recommended_List',
    component: () => import('../views/recommended_List.vue')
  },
  // 联登失败页(禁止申请)
  {
    path: '/thelarderOnce',
    name: 'prohibited_From_Applying',
    component: () => import('../views/prohibited_From_Applying.vue')
  },
  // 隐私协议
  {
    path: '/mentioned',
    name: 'privacyPolicy',
    component: () => import('../views/privacyPolicy.vue')
  },
  // 借款协议
  {
    path: '/thrilledas',
    name: 'serviceLoan',
    component: () => import('../views/serviceLoan.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
